import { React } from '../../common/3rd';

import { ReactRouterContextWidget } from '@/component/components';

import './widgets.scss';
class RenderUploadImg extends ReactRouterContextWidget {
  render() {
    return (
      <div className="upload-img">
        <img className="img" ref="img" src={this.getImageUrl()} alt="" />
        <input
          className="upload-button"
          type="file"
          ref="imgInput"
          accept="image/*"
          onChange={this.onImgChanged}
        />
      </div>
    );
  }
  onImgChanged = (event) => {
    let imgFile = this.refs.imgInput.files[0];
    if (!imgFile) {
      return;
    }
    let oFReader = new FileReader();
    oFReader.onload = (evt) => {
      let formData = new FormData();
      formData.append('file', imgFile);
      this.ajax(this.getFetchUrl(), null, {
        method: 'POST',
        cache: false,
        data: formData,
        processData: false,
        contentType: false,
      }).done((data) => {});
      this.refs.img.src = evt.target.result;
    };
    oFReader.readAsDataURL(imgFile);
  };
  getImageUrl() {
    return this.props.imgUrl || '';
  }
}

export default class UploadImg extends RenderUploadImg {
  getFetchUrl() {
    return this.props.fetchUrl;
  }
}
