/** 异步请求API管理 */
export { default as AsyncManager } from './AsyncManager';
/**
 * 路由锁
 * TODO: 09/01/2018 - TAG: by yanzhi.mo - 还没写好，写别用，可以用另外的那个实现
 */
export { default as BackLock } from './BackLock';
/** 投保流程字段补充，针对B2C */
export { default as FieldsSupplemented } from './FieldsSupplemented';
/** 原生方法调用 */
export { default as NativeCalled } from './NativeCalled';
/** 支付倒计时 */
export { default as PaymentTimer } from './PaymentTimer';
/** 微信支付 */
export { default as WxPayManager } from './WxPayManager';
/** 第三方接入 */
// export { default as ThirdPartyDocking } from "./ThirdPartyDocking";
export { default as n22 } from './n22';
export { parseQueryString, loadScript, deepFreeze } from '@/util/common';
export {
  getUserAgent,
  getPlatform,
  getMaxTouchPoints,
  getAppVersion,
  getWechatVersion,
  getVersion,
  isAppGreaterOrEquals,
  isGreaterOrEquals,
} from '@/util/compatibility';
export {
  getFullDate,
  getDate,
  getTime,
  formatTime,
  formatNow,
  format,
  replaceDateSeparator,
  isToday,
  getDayPart,
  birthdayFormat,
  getAgeByBirth,
} from '@/util/date';
export { isNetworkOffline, getNetworkType } from '@/util/network';
export {
  isAndroid,
  isIOS,
  isIOSChrome,
  isIE,
  isWechat,
  isWechatMini,
  isWechatMiniSDKReady,
  isBlobSupport,
  isAppError,
  isError,
  isRegExp,
  isUndefined,
  isNull,
  isNullOrUndefined,
  isObject,
  isFunction,
  isEmptyString,
  checkArgument,
  checkPhone,
} from '@/util/precondition';
export { isDataSchema } from '@/util/schema';
export {
  getLocal,
  setLocal,
  removeLocal,
  getProfile,
  setProfile,
  removeProfile,
  removeAuth,
  getOpenID,
  setOpenID,
  removeOpenID,
  getLockPolicy,
  setLockPolicy,
  removeLockPolicy,
  getMiniRequestType,
  setMiniRequestType,
  removeMiniRequestType,
} from '@/util/storage';
export { Wechat, mini } from '@/util/wechat';
