import Ajax from './ajax';
import * as Consts from './consts';
import Envs from './envs';
import Lang from './lang';
import Storage from './storage';
import Utils from './utils';
import Validation from './validation';
import Account from './account';
import Dec from './dec';
import Dictionary from './dictionary';
import Ocr from './ocrNew';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

export {
  Account,
  Ajax,
  Consts,
  Envs,
  Lang,
  Storage,
  Utils,
  Dec,
  Dictionary,
  Ocr,
  Validation,
  Swiper,
  // SwiperSlide,
  // Autoplay,
  // Pagination,
};
